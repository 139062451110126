import { BasicComponent } from "haxt";
import "./InlinedGallery.scss";
import { BrowserRequest } from "../../Common/BrowserRequest";
import { LangString } from "../../Data/Lang";
import { GalleryOverlay } from "./GalleryOverlay";

export class InlinedGallery extends BasicComponent<{
	lang: string;
	category: string;
}> {

	private category: string;
	private data: any[];

	public receivedProps(props: any, children: any) {
		super.receivedProps(props, children);
		this.getData();
	}

	public init() {
		this.getData();
	}

	public async getData() {
		let { category } = this.props;
		if (this.category !== category) {
			this.category = category;
			this.data = undefined!;
			this.update();
			this.data = (await BrowserRequest.get<any[]>("https://colinsgarden.net" + `/json/gallery/${category}`, {})).body;
			console.log(this.data);
			this.update();
		} else {
			this.update();
		}
	}

	private flag = true;

	public render() {
		if (this.data === undefined) {
			return <div>Loading...</div>;
		}
		let { lang } = this.props;
		let all = this.data.map((x: any) => { return { title: x.name, url: x.file }; });
		return (
			<div class="InlinedGallery">
				<h3>{LangString.gallery[lang]}</h3>
				<div class="images">
					{this.data.map((img, index) => <div class="image">
						<img src={"https://colinsgarden.net" + "/images/small/" + img.file} onClick={() => { GalleryOverlay.showGallery(all, index); }} />
					</div>)}
				</div>
			</div>
		);

	}
}
