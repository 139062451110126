import "./app.scss";
import { BasicComponent } from "haxt";
import { MainNav } from "./Components/MainNav";
import { Footer } from "./Components/Footer";
import { RouterOutlet } from "./Components/Router";

export interface AppProps {
}
export class App extends BasicComponent<AppProps> {

	public init() {
	}

	public render() {
		return (
			<div id="container">
				<MainNav />
				<RouterOutlet/>
				<Footer />
			</div>
		);

	}
}
