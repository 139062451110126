import { BasicComponent } from "haxt";
import { RouterOutlet } from "../Router";
import { BrowserRequest } from "../../Common/BrowserRequest";
import { Breeds } from "../../Data/Breeds";
import { Langs, YoungString, SexPluralString, LangString } from "../../Data/Lang";
import { OwnedPetPage } from "./OwnedPet";

import "./OwnedPets.scss";
import { OwnedPetsText } from "../Text/OwnedPetsText";
export class OwnedPetsPage extends BasicComponent<{
	lang: Langs;
	parts: string[];
}> {

	private breed: string;
	private data: any[];

	public receivedProps(props: any, children: any) {
		super.receivedProps(props, children);
		this.getData();
	}

	public init() {
		this.getData();
	}

	public async getData() {
		let { parts } = this.props;
		if (this.breed !== parts[0]) {
			this.breed = parts[0];
			this.data = undefined!;
			this.update();
			this.data = (await BrowserRequest.get<any[]>("https://colinsgarden.net" + `/json/owned/${parts[0]}`, {})).body;
			console.log(this.data);
			this.update();
		} else {
			this.update();
		}
	}

	private renderContent() {
		let { lang, parts } = this.props;
		let breed = Breeds[parts[0]];

		let out = [];

		let current = [];
		let lastSex = undefined;
		let lastCategory = undefined;
		for (let data of this.data) {
			if (lastSex !== undefined && lastSex !== undefined && (lastSex !== data.sex || lastCategory !== data.category)) {
				out.push(<div class="OwnedCategory">
					<h2>{breed.name[lang]} {lastCategory === "young" ? YoungString[lang] : ""} {SexPluralString[lastSex][lang]}</h2>
					<div class="OwnedList">{current}</div>
				</div>);
				current = [];
			}
			current.push(this.renderItem(data));
			lastSex = data.sex;
			lastCategory = data.category;
		}

		if (lastSex !== undefined && lastSex !== undefined) {
			out.push(<div class="OwnedCategory">
				<h2>{breed.name[lang]} {lastCategory === "young" ? YoungString[lang] : ""} {SexPluralString[lastSex][lang]}</h2>
				<div class="OwnedList">{current}</div>
			</div>);
		}

		return <div>{out}</div>;
	}

	private renderItem(data: any) {
		let { parts } = this.props;
		return <a class="Pet" {...OwnedPetsPage.link(`owned/${parts[0]}/${data.id}`)}>
			<div class="PetName">{data.data.title ? <div class="title">{data.data.title}</div> : ""} {data.name}</div>
			<img src={"https://colinsgarden.net" + "/images/small/" + data.img.file} />
		</a>;
	}

	private static link(url: string) {
		return {
			onClick: (event: MouseEvent) => {
				RouterOutlet.setPath(url);
				event.preventDefault();
			},
			href: RouterOutlet.get(url)
		}
	}

	public static renderControls() {
		let breed = Breeds[RouterOutlet.parts[0]];
		// <a onClick={(event) => this.navigate(event, "")} href={RouterOutlet.get("")}>Home</a>
		return (<div class="PetMenu">
			<div class="Link">
				<a {...OwnedPetsPage.link(`owned/${breed.id}/about`)}
					class={(RouterOutlet.page === "owned" && RouterOutlet.parts[1] === "about") ? "selected" : undefined}>
					{breed.about[RouterOutlet.lang]}
				</a>
			</div>

			<div class="Link">
				<a {...OwnedPetsPage.link(`owned/${breed.id}`)}
					class={(RouterOutlet.page === "owned" && RouterOutlet.parts[1] !== "about") ? "selected" : undefined}>
					{LangString.owned[RouterOutlet.lang]} {breed.type.adult[RouterOutlet.lang]}
				</a>
			</div>
			<div class="Link">
				<a {...OwnedPetsPage.link(`pets/${breed.id}`)}
					class={(RouterOutlet.page === "pets") ? "selected" : undefined}>
					{breed.type.child[RouterOutlet.lang]}
				</a>
			</div>
		</div>);
	}

	public render() {
		let { lang, parts } = this.props;
		let breed = Breeds[parts[0]];

		if (this.data === undefined) {
			return <div class="OwnedPets">
				<h1>{breed.name[lang]}</h1>
				{OwnedPetsPage.renderControls()}
				<div>Loading...</div>
			</div>;
		}

		if (parts.length > 1) {
			if (parts[1] === "about") {
				return (
					<div class="OwnedPets">
						<h1>{breed.name[lang]}</h1>
						{OwnedPetsPage.renderControls()}
						{OwnedPetsText[parts[0]][lang]}
					</div>
				);
			} else {
				return (
					<OwnedPetPage data={this.data.find(x => x.id === +parts[1])} lang={lang} />
				);
			}
		} else {
			return (
				<div class="OwnedPets">
					<h1>{breed.name[lang]}</h1>
					{OwnedPetsPage.renderControls()}
					{this.renderContent()}
				</div>
			);
		}

	}
}
