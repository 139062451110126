

let catsName = {
	adult: {
		en: "Cats",
		pl: "Koty"
	},
	child: {
		en: "Kittens",
		pl: "Kocięta"
	}
};

let dogsName = {
	adult: {
		en: "Dogs",
		pl: "Psy"
	},
	child: {
		en: "Puppies",
		pl: "Szczeniaki"
	}
};

export let Breeds: {
	[K: string]: {
		id: string;
		pickup: number;
		kind: "cat" | "dog";
		name: { en: string; pl: string; }
		type: {
			adult: { en: string; pl: string; };
			child: { en: string; pl: string; };
		}
		about: { en: string; pl: string; }
	}
} = {
	"rag": {
		id: "rag",
		kind: "cat",
		pickup: 13 * 7 * 24 * 60 * 60 * 1000, // 13 weeks
		name: {
			en: "Ragolls",
			pl: "Ragdolle"
		},
		type: catsName,
		about: {
			en: "About Ragolls",
			pl: "O Ragdollach"
		},
	},
	"rus": {
		id: "rus",
		kind: "cat",
		pickup: 13 * 7 * 24 * 60 * 60 * 1000, // 13 weeks
		name: {
			en: "Russian Blue",
			pl: "Rosyjskie Niebieskie"
		},
		type: catsName,
		about: {
			en: "About Russian Blue",
			pl: "O Rosyjskich Niebieskich"
		},
	},
	"mco": {
		id: "mco",
		kind: "cat",
		pickup: 13 * 7 * 24 * 60 * 60 * 1000, // 13 weeks
		name: {
			en: "Maine Coon",
			pl: "Maine Coony"
		},
		type: catsName,
		about: {
			en: "About Maine Coon",
			pl: "O Maine Coonach"
		},
	},
	"rtoy": {
		id: "rtoy",
		kind: "dog",
		pickup: 8 * 7 * 24 * 60 * 60 * 1000, // 8 weeks
		name: {
			en: "Russian Toy Terrier",
			pl: "Rosyjskie Toy Terriery"
		},
		type: dogsName,
		about: {
			en: "About Russian Toy Terrier",
			pl: "O Rosyjskich Toy Terierach"
		},
	}
};
