import { BasicComponent } from "haxt";
import "./Contact.scss";

export class ContactPage extends BasicComponent<{
	lang: string;
	parts: string[];
}> {

	public willReceiveProps() {
		this.update();
	}

	public init() {
	}

	private flag = true;

	public render() {
		if (this.props.lang === "en") {
			return (
				<div class="Contact">
					<h1>Contact</h1>
					Phone (Beata) 085-151-6077<br/>
					Connolly, Co.Clare, Ireland<br/>
					Email: colinsgarden@gmail.com
				</div>
			);
		} else {
			return (
				<div class="Contact">
					<h1>Kontakt</h1>
					Telefon (Beata) 085-151-6077<br/>
					Connolly, Co.Clare, Irlandia<br/>
					Email: colinsgarden@gmail.com
				</div>
			);
		}

	}
}
