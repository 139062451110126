import { BasicComponent } from "haxt";
import { BasicEvent } from "@h4x/common";
import { PageHandler, RouterPages } from "./RouterPages";
import { Langs } from "../Data/Lang";
import { Notice } from "./Notice";


export class RouterOutlet extends BasicComponent {

	public static readonly pathURL: string;
	public static readonly lang: Langs;
	public static readonly page: string;
	public static readonly parts: string[];
	public static readonly onUpdate = new BasicEvent<() => void>();

	private static pre = "";
	private static handler: PageHandler;

	public static get path() {
		return RouterOutlet.pathURL;
	}

	public static get(url: string) {
		url = url.toLowerCase();
		if (!url.startsWith("/")) {
			url = "/" + RouterOutlet.lang + "/" + url;
		}
		return url;
	}

	public static setPath(url: string, history = true) {
		url = RouterOutlet.get(url);
		if (RouterOutlet.pathURL !== url) {
			RouterOutlet.load(url, history);
			RouterOutlet.onUpdate.execute();
			scroll(0, 0);
		}
	}


	public static getLang(lang: string) {
		lang = lang.toLowerCase();
		let url;
		if (RouterOutlet.parts.length > 0) {
			url = "/" + lang + "/" + RouterOutlet.page + "/" + RouterOutlet.parts.join("/");
		} else if (RouterOutlet.page !== "index") {
			url = "/" + lang + "/" + RouterOutlet.page;
		} else {
			url = "/" + lang + "/";
		}
		return url;
	}

	public static changeLang(lang: string) {
		lang = lang.toLowerCase();
		if (RouterOutlet.lang !== lang) {
			let url;
			if (RouterOutlet.parts.length > 0) {
				url = "/" + lang + "/" + RouterOutlet.page + "/" + RouterOutlet.parts.join("/");
			} else if (RouterOutlet.page !== "index") {
				url = "/" + lang + "/" + RouterOutlet.page;
			} else {
				url = "/" + lang + "/";
			}
			RouterOutlet.load(url, true);
			RouterOutlet.onUpdate.execute();
		}
	}

	public static load(url: string, history: boolean) {
		if (url.startsWith("/prototype")) { RouterOutlet.pre = "/prototype"; url = url.substring("/prototype".length) }
		let { path, lang, page, parts } = RouterOutlet.getPage(url);

		(RouterOutlet as any).pathURL = path;
		(RouterOutlet as any).lang = lang.toLowerCase();
		(RouterOutlet as any).page = page.toLowerCase();
		(RouterOutlet as any).parts = parts;
		RouterOutlet.handler = RouterPages[page] || RouterPages["error"]!;
		if (history === true) {
			window.history.pushState({}, "", window.location.origin + RouterOutlet.pre + RouterOutlet.pathURL);
		}
	}

	public static getPage(url: string) {
		let path = url;
		let parts = path.split("/").filter(x => x !== "");
		let lang = parts.shift() || "en";
		let page = parts.shift() || "index";
		return { path: path, lang: lang, page: page, parts: parts };
	}

	public init() {
		RouterOutlet.onUpdate.addCallback(() => { this.update(); });
	}

	public render() {

		return (
			<div class="main">
				{/*<div class="debug">
					DEBUG<br />
					Path: {RouterOutlet.path}<br />
					Lang: {RouterOutlet.lang}<br />
					Page: {RouterOutlet.page}<br />
					Parts: {JSON.stringify(RouterOutlet.parts)}<br />
				</div>*/}
				{/*<div class="wrapper">
					<div class="shadow-bottom" />
					<div class="content">
						<Notice lang={RouterOutlet.lang}/>
					</div>
				</div>*/}
				<div class="wrapper">
					<div class="shadow-top" />
					<div class="content">
						{RouterOutlet.handler.render(RouterOutlet.lang, RouterOutlet.parts)}
					</div>
					<div class="shadow-bottom" />
				</div>
			</div>
		);

	}
}

RouterOutlet.load(window.location.pathname, false);

window.addEventListener("popstate", () => {
	RouterOutlet.setPath(window.location.pathname, false);
});
