import { BasicComponent } from "haxt";
import "./Index.scss";

export class IndexPage extends BasicComponent<{
	lang: string;
	parts: string[];
}> {

	public willReceiveProps() {
		this.update();
	}

	public init() {
	}

	private flag = true;

	public render() {
		if (this.props.lang === "en") {
			return (
				<div class="Index">
					We are breeding cats since 2002. For the last 10 years our cattery was located in Limerick, Ireland.
					In June of 2016 we have moved to our new home in Connolly, Co.Clare.<br />
					Currently we are breeding Russian Blue cats and Russian Toy dogs.<br/>
					They are our hobby, because we simply love animals...<br />
					All cats in my breed have genealogy and are pedigree.<br />
					Our cats are members and have registration papers from<br />
					<img src="./assets/logo_tica.png" /><br />
				</div>
			);
		} else {
			return (
				<div class="Index">
					Kotami rasy Maine Coon zajmowaliśmy się już w Polsce od 2002 roku, następnie przez 10 lat nasza hodowla mieściła się w Limerick,
					w Czerwcu 2016 przenieśliśmy się do naszego domu na wsi w Connolly Co.Clare w Irlandii.<br />
					Aktualnie zajmujemy się hodowlą kotów rasy Russian Blue oraz psów rasy Russian Toy.<br />
					Są one naszym hobby, bo my po prostu kochamy zwierzęta...<br />
					Wszystkie koty sprzedawane z mojej hodowli są rasowe z rodowodem.<br />
					Nasze koty należą do<br />
					<img src="./assets/logo_tica.png" /><br />
				</div>
			);
		}

	}
}
