import { BasicComponent } from "haxt";
import "./Footer.scss";
import { RouterOutlet } from "./Router";

export class Footer extends BasicComponent {

	public init() {
		RouterOutlet.onUpdate.addCallback(() => {
			this.update();
		});
	}

	@BasicComponent.bind()
	private navigate(event: MouseEvent, url: string) {
		RouterOutlet.setPath(url);
		this.update();
		event.preventDefault();
	}

	public render() {
		let lang = RouterOutlet.lang;
		if (lang === "en") {
			return (
				<div class="Footer">
					<div class="left">
						<div class="nav">
							<a onClick={(event) => this.navigate(event, "")} href={RouterOutlet.get("")}>Home</a>
							{/*<a onClick={(event) => this.navigate(event, "owned/rag")} href={RouterOutlet.get("owned/rag")}>Ragdoll</a>*/}
							<a onClick={(event) => this.navigate(event, "owned/rus")} href={RouterOutlet.get("owned/rus")}>Russian Blue</a>
							<a onClick={(event) => this.navigate(event, "owned/mco")} href={RouterOutlet.get("owned/mco")}>Maine Coon</a>
							<a onClick={(event) => this.navigate(event, "owned/rtoy")} href={RouterOutlet.get("owned/rtoy")}>Russian Toy Terrier</a>
						</div>
					</div>
					<div class="logo-bottom" />
					<div class="right">
						<ul class="nav">
							<a onClick={(event) => this.navigate(event, "contact")} href={RouterOutlet.get("contact")}>
								<div class="icon-contact" /><span class="text-contact">Contact</span>
							</a>
							<a href="https://www.facebook.com/profile.php?id=100008815092651">
								<div class="icon-facebook" /><span class="text-facebook">Facebook</span>
							</a>
							{/*<a onClick={(event) => this.navigate(event, "guestbook")} href={RouterOutlet.get("guestbook")}>
								<div class="icon-guestbook" /><span class="text-guestbook">Guestbook</span>
							</a>*/}
						</ul>
					</div>
				</div>
			);
		} else {
			return (
				<div class="Footer">
					<div class="left">
						<div class="nav">
							<a onClick={(event) => this.navigate(event, "")} href={RouterOutlet.get("")}>Home</a>
							{/*<a onClick={(event) => this.navigate(event, "owned/rag")} href={RouterOutlet.get("owned/rag")}>Ragdolle</a>*/}
							<a onClick={(event) => this.navigate(event, "owned/rus")} href={RouterOutlet.get("owned/rus")}>Rosyjskie Niebieskie</a>
							<a onClick={(event) => this.navigate(event, "owned/mco")} href={RouterOutlet.get("owned/mco")}>Maine Coony</a>
							<a onClick={(event) => this.navigate(event, "owned/rtoy")} href={RouterOutlet.get("owned/rtoy")}>Rosyjskie Toy Teriery</a>
						</div>
					</div>
					<div class="logo-bottom" />
					<div class="right">
						<ul class="nav">
							<a onClick={(event) => this.navigate(event, "contact")} href={RouterOutlet.get("contact")}>
								<div class="icon-contact" /><span class="text-contact">Kontakt</span>
							</a>
							<a href="https://www.facebook.com/profile.php?id=100008815092651">
								<div class="icon-facebook" /><span class="text-facebook">Facebook</span>
							</a>
							{/*<a onClick={(event) => this.navigate(event, "guestbook")} href={RouterOutlet.get("guestbook")}>
								<div class="icon-guestbook" /><span class="text-guestbook">Księga<br />Gości</span>
							</a>*/}
						</ul>
					</div>
				</div>
			);
		}

	}
}
