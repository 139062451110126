import { render, Component, h, options, ComponentChild, BasicComponent, ComponentChildren, Overlay } from "haxt";
import { ErrorResponse } from "@h4x/common";

options.debug = true;

import * as TSX from "haxt";
(global as any).h = TSX.h;

declare global {
	let h: typeof TSX.h;
}

Overlay.create(document.getElementById("overlay-container") as HTMLElement);


class ErrorAlert extends BasicComponent<{
	[Component.Children]: ComponentChildren
}> {

	@BasicComponent.bind()
	private onClose() {
		Overlay.removeAlert(this);
	}

	public render() {
		return <div style={{
			"margin": "10px 20px",
			"padding": 10,
			"color": "#CCC",
			"background-color": "#25384e",
			"border": "2px solid #95384e",
			"box-shadow": "0px 2px 8px 1px #F00",
			"border-radius": 10,
			"padding-right": 25,
			"position": "relative"
		}}>
			<i class="far fa-times-circle" style={{ "position": "absolute", "top": 5, "right": 5 }} onClick={this.onClose}></i>
			<span style={{ "color": "#F00", "font-weight": "bold" }}>Error! </span>
			{this.children}
		</div>;
	}
}

window.onerror = (message, filename, lineno, colno, error) => {
	if (error instanceof ErrorResponse) {
		// Overlay.
		Overlay.addAlert(<ErrorAlert>{error.message}</ErrorAlert>);
		console.warn(error);
		return true;
	}
	return false;
};

window.addEventListener("unhandledrejection", (event: PromiseRejectionEvent) => {
	let error = event.reason;
	if (error instanceof ErrorResponse) {
		Overlay.addAlert(<ErrorAlert>{error.message}</ErrorAlert>);
		console.warn(error);
		event.preventDefault();
		return true;
	}
	return false;
});


import { App } from "./app";

let vdom = <App></App>;
console.log(vdom);
let node = render(vdom, document.getElementById("app") as HTMLElement);
console.log(node);

