import { BasicComponent } from "haxt";
import "./OwnedPet.scss";
import { SexParentString, LangString } from "../../Data/Lang";
import { InlinedGallery } from "../Shared/InlinedGallery";

export class OwnedPetPage extends BasicComponent<{
	data: any;
	lang: string;
	/*breed: string;*/
}> {
	public willReceiveProps() {
		this.update();
	}

	public init() {
	}

	public render() {
		let { lang, data/*, breed*/ } = this.props;

		/*let breed = Breeds[parts[0]];*/

		let birth = new Date(data.data.born * 1000);
		let birthY = birth.getFullYear();
		let birthM = ("0" + (birth.getMonth() + 1)).substr(-2);
		let birthD = ("0" + birth.getDate()).substr(-2);
		return (
			<div class="OwnedPet">
				<div class="info-box">
					<div class="image">
						<img src={"https://colinsgarden.net" + "/images/normal/" + data.img.file} />
					</div>
					<div class="info">
						<h1>{data.data.title ? <div class="title">{data.data.title}</div> : ""}{data.name}</h1>

						{data.data.color ? <div class="color">{LangString.color[lang]}: {data.data.color}</div> : undefined}
						<div class="born">{LangString.born[lang]}: {birthD}-{birthM}-{birthY}</div>

						<div class="filler"></div>

						{(data.data.blood && data.data.blood !== "00") ? <div class="blood">{LangString.blood[lang]}: {data.data.blood}</div> : undefined}
						{data.data.test_hcm === "1" ? <div class="hcm">{LangString.test_hcm[lang]}</div> : undefined}
						{data.data.test_pkd === "1" ? <div class="pkd">{LangString.test_pkd[lang]}</div> : undefined}

						<div class="filler"></div>

						<div class="sire">{SexParentString[1][lang]}: {data.data.sire}</div>
						<div class="dam">{SexParentString[0][lang]}: {data.data.dam}</div>
					</div>
				</div>
				<InlinedGallery lang={lang} category={"owned" + data.id} />
			</div>
		);

	}
}
