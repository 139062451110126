export type Langs = "en" | "pl";


export const YoungString = {
	en: "Young",
	pl: "Młode"
};

export const SexPluralString: { [K: number]: { [K: string]: string } } = {
	0: {
		en: "Dams",
		pl: "Samice"
	},
	1: {
		en: "Sires",
		pl: "Samce"
	}
};

export const SexString: { [K: number]: { [K: string]: string } } = {
	0: {
		en: "Dam",
		pl: "Samica"
	},
	1: {
		en: "Sire",
		pl: "Samiec"
	}
};

export const SexParentString: { [K: number]: { [K: string]: string } } = {
	0: {
		en: "Dam",
		pl: "Matka"
	},
	1: {
		en: "Sire",
		pl: "Ojciec"
	}
};

export const LangString: { [K: string]: { [K: string]: string } } = {
	born: {
		en: "Date of birth",
		pl: "Dzień urodzenia"
	},
	pickup: {
		en: "Ready to go",
		pl: "Data odbioru"
	},
	pickupReady: {
		en: "Ready to go",
		pl: "Gotowe do odbioru"
	},
	color: {
		en: "Color",
		pl: "Kolor"
	},
	blood: {
		en: "Blood",
		pl: "Grupa krwi"
	},
	test_hcm: {
		en: "HCM: N/N",
		pl: "HCM: N/N"
	},
	test_pkd: {
		en: "PKD: N/N",
		pl: "PKD: N/N"
	},
	gallery: {
		en: "Gallery",
		pl: "Zdjęcia"
	},
	owned: {
		en: "Our",
		pl: "Nasze"
	},
	parents: {
		en: "Parents",
		pl: "Rodzice"
	}
};
