
import { OwnedPetsPage } from "./Pages/OwnedPets";
import { IndexPage } from "./Pages/Index";
import { ContactPage } from "./Pages/Contact";
import { GuestbookPage } from "./Pages/Guestbook";
import { PetsPage } from "./Pages/Pets";
import { RehomingPage } from "./Pages/Rehoming";
import { Langs } from "../Data/Lang";

export type PageHandler = {
	render: (lang: Langs, parts: string[]) => JSX.Element;
};

export const RouterPages: { [K: string]: PageHandler | undefined } = {
	"index": {
		render: (lang, parts) => <IndexPage lang={lang} parts={parts} />
	},
	"owned": {
		render: (lang, parts) => <OwnedPetsPage lang={lang} parts={parts} />
	},
	"pets": {
		render: (lang, parts) => <PetsPage lang={lang} parts={parts} />
	},
	"contact": {
		render: (lang, parts) => <ContactPage lang={lang} parts={parts} />
	},
	"guestbook": {
		render: (lang, parts) => <GuestbookPage lang={lang} parts={parts} />
	},
	"rehoming": {
		render: (lang, parts) => <RehomingPage lang={lang} parts={parts} />
	},
	"error": {
		render: () => <div>Error 404</div>
	}
};
