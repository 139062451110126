import { BasicComponent } from "haxt";
import "./Pets.scss";
import { Breeds } from "../../Data/Breeds";
import { RouterOutlet } from "../Router";
import { OwnedPetsPage } from "./OwnedPets";
import { Langs, LangString, SexParentString } from "../../Data/Lang";
import { BrowserRequest } from "../../Common/BrowserRequest";
import { PetsText } from "../Text/PetsText";
import { GalleryOverlay } from "../Shared/GalleryOverlay";

export class PetsPage extends BasicComponent<{
	lang: Langs;
	parts: string[];
}> {

	private litter: string;
	private data: any[];

	public receivedProps(props: any, children: any) {
		super.receivedProps(props, children);
		this.getData();
	}

	public init() {
		this.getData();
	}

	public async getData() {
		let { parts } = this.props;
		if (this.litter !== parts[0]) {
			this.litter = parts[0];
			this.data = undefined!;
			this.update();
			this.data = (await BrowserRequest.get<any[]>("https://colinsgarden.net" + `/json/litters/${parts[0]}`, {})).body;
			console.log(this.data);
			this.update();
		} else {
			this.update();
		}
	}

	private renderContent() {
		let { lang, parts } = this.props;
		let breed = Breeds[parts[0]];

		let out = [];

		for (const data of this.data) {
			let litter = [];
			litter.push(<h3>{LangString.parents[lang]}</h3>);
			litter.push(<div class="Parents">
				{this.renderParent(PetsText.parent.female[lang], data.parents[0])}
				{this.renderParent(PetsText.parent.male[lang], data.parents[1])}
			</div>);
			litter.push(<h4>{breed.type.child[lang]}</h4>);

			let birth = new Date(data.litter.born * 1000);
			let pickup = new Date(data.litter.born * 1000 + breed.pickup);

			let birthY = birth.getFullYear();
			let birthM = ("0" + (birth.getMonth() + 1)).substr(-2);
			let birthD = ("0" + birth.getDate()).substr(-2);

			let pickupY = pickup.getFullYear();
			let pickupM = ("0" + (pickup.getMonth() + 1)).substr(-2);
			let pickupD = ("0" + pickup.getDate()).substr(-2);

			let ready = pickup.getTime() < Date.now();
			litter.push(<div class="LitterInfo">
				<div class="born">{LangString.born[lang]}: {birthD}-{birthM}-{birthY}</div>
				{ready ? <div class="born">{LangString.pickupReady[lang]}</div> :
					<div class="born">{LangString.pickup[lang]}: {pickupD}-{pickupM}-{pickupY}</div>}
			</div>);
			let pets = [];
			for (const pet of data.pets) {
				pets.push(this.renderPet(pet));
			}
			litter.push(<div class="Pets">{pets}</div>);
			out.push(<div class="Litter">{litter}</div>);
		}

		return out;
	}

	private renderParent(title: string, data: any) {
		let { lang, parts } = this.props;
		return (<a class="info-box" onClick={() => RouterOutlet.setPath(`owned/${parts[0]}/${data.id}`)} href={RouterOutlet.get(`owned/${parts[0]}/${data.id}`)}>
			<div class="image">
				<img src={"https://colinsgarden.net" + "/images/small/" + data.img.file} />
			</div>
			<div class="info">
				<h3>{title}</h3>
				<h3>{data.data.title ? <div class="title">{data.data.title}</div> : ""}{data.name}</h3>

				{data.data.color ? <div class="color">{LangString.color[lang]}: {data.data.color}</div> : undefined}
			</div>
		</a>);
	}

	private renderPet(data: any) {
		let { lang } = this.props;
		let colors: any = {
			"Reserved": "#A00",
			"Option": "#8A0",
			"Available": "#0A0",
		};
		return <div class="Pet">
			<div class="image" onClick={() => { GalleryOverlay.showGallery(data.images.map((x: any) => { return { title: x.name, url: x.file }; })); }}>
				<img src={"https://colinsgarden.net" + "/images/small/" + data.images[0].file} />
			</div>
			<div class="info">
				<h3>{data.data.title ? <div class="title">{data.data.title}</div> : ""}{data.name}</h3>
				<div class="sex">{PetsText[data.data.sex ? "male" : "female"][lang]}</div>

				{data.data.color ? <div class="color">{LangString.color[lang]}: {data.data.color}</div> : undefined}
				<div class="filler"></div>
				<div class="status" style={{ color: colors[data.status] }}>{data.status} {data.data.status_description}</div>
			</div>
		</div>;
	}

	public render() {
		let { lang, parts } = this.props;
		let breed = Breeds[parts[0]];

		if (this.data === undefined) {
			return <div class="Pets">
				<h1>{breed.name[lang]} {breed.type.child[lang]}</h1>
				{OwnedPetsPage.renderControls()}
				{PetsText.info[breed.kind][lang]}
				<div>Loading...</div>
			</div>;
		}

		return (
			<div class="Pets">
				<h1>{breed.name[lang]} {breed.type.child[lang]}</h1>
				{OwnedPetsPage.renderControls()}
				{PetsText.info[breed.kind][lang]}
				{this.renderContent()}
			</div>
		);
	}
}
