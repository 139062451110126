import { BasicComponent } from "haxt";
import "./Rehoming.scss";

export class RehomingPage extends BasicComponent<{
	lang: string;
	parts: string[];
}> {

	public willReceiveProps() {
		this.update();
	}

	public init() {
	}

	private flag = true;

	public render() {
		if (this.props.lang === "en") {
			return (
				<div class="Rehoming">
					Looking for new home
				</div>
			);
		} else {
			return (
				<div class="Rehoming">
					Szukające nowego domu
				</div>
			);
		}

	}
}
