import { BasicComponent, Overlay, $base } from "haxt";
import "./GalleryOverlay.scss";

import scrollIntoView from "scroll-into-view-if-needed";

export type ImageData = {
	title: string;
	url: string;
};

export class GalleryOverlay extends BasicComponent<{
	images: ImageData[];
	selected?: number;
}> {
	private selected: number;

	@BasicComponent.initialize()
	public initGallery() {
		this.selected = this.props.selected! | 0;
	}

	public willReceiveProps() {
		this.update();
	}

	@BasicComponent.bind()
	public close() {
		Overlay.remove(this);
	}

	@BasicComponent.bind()
	public next() {
		if (this.selected < this.props.images.length - 1) {
			this.selected++;
			this.update();
		}
	}

	@BasicComponent.bind()
	public prev() {
		if (this.selected > 0) {
			this.selected--;
			this.update();
		}
	}

	private scrollToSelected() {
		if (this[$base] === undefined || this[$base].node === undefined) { return; }
		let parent = (this[$base].node as HTMLElement);
		let select = parent.getElementsByClassName("selected")[0];
		scrollIntoView(select, { behavior: "smooth", block: "center", inline: "center", boundary: parent });
	}

	public render() {
		setTimeout(() => {
			this.scrollToSelected();
		}, 10);
		return (<div class="GalleryOverlayWrapper" onClick={this.close}>
			<div class="GalleryOverlay" onClick={(event) => { event.stopPropagation(); event.preventDefault(); }}>
				<div class="Main">
					<img src={"https://colinsgarden.net" + "/images/normal/" + this.props.images[this.selected].url} /> <br />
					<div class="left" onClick={this.prev}></div>
					<div class="right" onClick={this.next}></div>
				</div>
				<div class="Preview">
					<div class="PreviewImages">
						{this.props.images.map((img, index) =>
							<img
								src={"https://colinsgarden.net" + "/images/icon/" + img.url}
								class={{ selected: (this.selected === index) }}
								onClick={() => { this.selected = index; this.update(); }}
							/>
						)}
					</div>
				</div>
				<div class="GalleryClose" onClick={this.close}>X</div>
			</div>
		</div>);
	}

	public static showGallery(images: ImageData[], selected?: number) {
		Overlay.add(<GalleryOverlay images={images} selected={selected}></GalleryOverlay>);
	}
}

