export const OwnedPetsText: { [K: string]: { [K: string]: JSX.Element } } = {
	rus: {
		pl: (
			<div>
				<h2>Historia</h2>

				<div>
					Koty rosyjskie niebieskie to naturalna rasa (co oznacza, że nie powstała w wyniku krzyżowania ras)
					pochodząca prawdopodobnie z Rosji, z okolic Archangielska. Podobno były bardzo lubiane przez rosyjskich
					carów. W latach sześćdziesiątych XIX wieku koty te zostały sprowadzone do Anglii i przez pewien czas
					nazywano je "kotami archangielskimi". W roku 1875 zostały po raz pierwszy wystawione w Anglii, wtedy
					jeszcze w klasie "kotów o umaszczeniu niebieskim". W 1912 roku otrzymały już swoja własną klasę
					wystawową. Znany nam dziś wygląd kotów rosyjskich ukształtował się w latach 60 - tych.
				</div>

				<h2>Charakterystyka</h2>

				<div>
					Koty rosyjskie niebieskie mają ciało średniej wielkości, nieco wydłużone i harmonijne. Nogi są długie i
					silne a jednocześnie delikatne i proporcjonalne do ciała. Wąskie poduszeczki mają owalny kształt. Czasami
					można odnieść wrażenie, że koty te chodzą na palcach. Głowa u kotów rosyjskich ma kształt klina. Ich
					prosty nos ma zawsze kolor niebieskoszary. Uszy są duże i ostro zakończone - skóra na uszach jest bardzo
					delikatna i sprawia wrażenie przezroczystej. Duże oczy mają niezwykle intensywny zielony kolor.
				</div>
				<div>
					Charakterystyczny dla tej rasy jest kolor umaszczenia - wyważona barwa niebieska bez jakichkolwiek plam,
					z wyraźnym srebrzystym połyskiem. Futro jest krótkie i gęste, w dotyku miękkie i jedwabiste.
				</div>
				<div>
					Koty te mają charakterystyczny wyraz pyszczka przypominający uśmiech. Ich głos jest łagodny i przyjemny.
					Poza tym koty rosyjskie cieszą się raczej dobrym zdrowiem i dożywają kilkunastu i więcej lat.
				</div>

				<h2>Temperament</h2>
				<div>
					Rosyjskie koty niebieskie są inteligentne i wrażliwe. Bardzo przywiązują się do swoich właścicieli i
					starają się towarzyszyć im na każdym kroku. Są zwinne i ruchliwe. Lubią skakać i wspinać się ale rzadko
					kiedy coś zniszczą. Chętnie też bawią się małymi zabawkami. Potrafią na wiele sposobów okazywać swoje
					uczucia i bardzo się smucą, kiedy zostaną odtrącone. Na co dzień zwierzęta te emanują spokojem i
					opanowaniem mimo, że mogą być pełne temperamentu. Nie są strachliwe ale obcych traktują z rezerwą.
					Skłaniają się raczej ku ludziom spokojnym i zrównoważonym. Z reguły dobrze czują się w towarzystwie
					dzieci. Akceptują też towarzystwo psów i innych zwierząt domowych. Ponieważ są to zwierzęta bardzo
					inteligentne, można je dobrze ułożyć i nauczyć wielu rzeczy.
				</div>

				<h2>Pielęgnacja</h2>
				<div>
					Koty rosyjskie z reguły mają dobry apetyt, dlatego należy uważać aby zwierzęcia nie utuczyć. Pielęgnacja
					futra jest łatwa ponieważ koty te linieją w bardzo małym stopniu. Wystarczy czesać je około raz w
					tygodniu.
				</div>
			</div>
		),
		en: (
			<div>
				<h2>WHAT IS A RUSSIAN BLUE?</h2>
				<div>
					The Russian Blue is a short haired cat of foreign type, but it is not as extreme in "type" as the Siamese
					and other orientals. The body is long and graceful with medium strong bone. Legs are long with small oval
					paws and the tail is fairly long and tapering. The short, wedge-shaped head is topped by large pointed
					ears, set vertically to the head. The Russian Blue has prominent whisker pads and vivid green almond
					shaped eyes. The coat of the Russian Blue is quite different from that of any other breed, being short,
					thick but fine and standing up soft and silky with a silvery sheen. A good Russian Blue should have an
					elegant appearance with an aristocratic expression.
				</div>

				<h2>WHAT IS ITS HISTORY?</h2>
				<div>
					The Russian Blue Breeders Association has published an informative and interesting handbook which
					includes a detailed history of the Russian Blue. It is thought that the cats were first brought to this
					country by sailors in 1860 from the port of Archangel. They were then known as Archangel cats.
				</div>

				<h2>WHAT KIND OF PERSONALITY DOES IT HAVE?</h2>
				<div>
					Naturally, this will vary from cat to cat, but the Russian Blue is an intelligent cat which is renowned
					for its gentleness. It will often talk to its owner in a quiet voice, although a queen in season can make
					as much noise as any other cat. The Russian Blue is affectionate but not demanding. Many love to be
					picked up and cuddled but some are not so keen. Nevertheless, they are still likely to be loving and
					devoted companions to their owners. This has made the breed particularly popular with elderly people.
					However, it is also very good with children and as a general rule will tolerate the clumsy handling of a
					child. The cat will wriggle, rather than claw, its way out of a tight grip. It is rare for a Russian Blue
					to bite or scratch.
				</div>

				<h2>CAN A RUSSIAN BLUE BE KEPT INDOORS?</h2>
				<div>
					Although the coat is ideally suited to cold weather, the Russian Blue tends to be a home loving cat and
					should not suffer from being confined to the house, provided there is ample opportunity for adventure and
					play with its owner. If the cat would be left alone for much of the day, it would be kinder to get two.
					If you need to keep your cat indoors do bear in mind that you will need to keep windows screened and that
					it is difficult to keep a cat in if you have young children or frequent visitors at your door.
				</div>

				<h2>STANDARD OF POINTS</h2>

				<div>
					The following is reprinted with kind permission from the GCCFI and is taken from their "Standard of
					Points" handbook.
				</div>

				<h2>General Type Standard</h2>

				<div>
					The Russian is a cat of elegant foreign type without the exaggerated elongation of the Oriental. Its high
					ears, green almond-shaped eyes, angled profile and prominent whisker pads create a distinctive gentle
					expression which, together with its essential velvety double coat, gives the breed its unique charm.
				</div>

				<div>
					<b>Head</b> - The wedge from tip of nose to eyes is short. The distance from eyes to ears should be equal
					to or slightly longer than the wedge. The top of the head should appear flat between the ears. The
					whisker pads are prominent giving a broad end to the head. In profile the forehead should appear straight
					to the level of the upper edge of the eye. The profile from here to the tip of the nose has such a
					shallow concave curve that it appears virtually straight. This creates a distinctive angle level with the
					upper edge of the eye. There must be no stop, break or nose bump, nor must there be a straight profile
					from top of head to nose tip. The tip of the chin should line up with the tip of the nose in the same
					vertical plane. The chin should be strong and deep with a level bite. The back of the head should not
					fall away. In kittens the top of the head may appear more rounded and the profile angle may not be as
					well developed.
				</div>

				<div>
					<b>Ears</b> - Large and pointed, wide at the base and set vertically to the head. The ear placement is
					such that the outer line of the ears continues the line of the upper part of the face, although in mature
					males with jowls this will be less apparent. There should be little inside hair.
				</div>

				<div>
					<b>Eyes</b> - Vivid green. Set rather wide apart, almond in shape, not small and deep set. In kittens
					allowance should be made for eye colour. Body - Long and graceful in outline and carriage. Medium strong
					bone, well muscled. Cobby or heavy build undesirable. The females may be slightly smaller than the males
					but should also be well muscled.
				</div>

				<div>
					<b>Legs and Feet</b> - Long legs. Feet small and oval.
				</div>

				<div>
					<b>Tail</b> - Moderately long and in proportion to the body. Tapering, neither blunt-ended nor whip.
				</div>

				<div>
					<b>Coat</b> - The coat is double with a very dense undercoat: the whole coat is very fine, short and soft
					with no harsh feel to it, very different in texture from any other breed and the truest criterion of the
					Russian.
				</div>

				<div>
					<b>Condition and Temperament</b> - In the Russian the condition and temperament of the cat are important
					considerations.
				</div>
			</div>
		)
	},
	rag: {
		pl: (
			<div>
				<h2>Historia nazwy</h2>

				<div>
					Ragdoll (ang. szmaciana lalka) – rasa kotów zaliczana do kotów półdługowłosych. Nazwa rasy pochodzi od
					szeroko rozpowszechnionej legendy, wg której – gdy ragdolla weźmie się na ręce, kot odpręża się (mięśnie
					się rozluźniają) i staje sie uległy w stopniu przewyższającym inne rasy kotów. W celu popularyzacji rasy
					rozpowszechniano też mit o rzekomej odporności ragdolli na ból.
				</div>

				<h2>Historia rasy</h2>

				<div>
					Ragdolle zostały wyhodowane w latach 60. ubiegłego wieku w Kalifornii. Odkrywczynią i właścicielką
					pierwszych kotów tej rasy jest Ann Baker - kalifornijska hodowczyni kotów perskich z miejscowości
					Riverside. Za protoplastkę rasy uważa się półdziką, długowłosą, białą kotkę o imieniu Josephine, która
					wyglądem przypominała angorę. Kotkę tę uratowano, po tym jak została potrącona przez samochód i udało się
					ją całkowicie oswoić. Josephine została skrzyżowana z kotem birmańskim, a następnie była krzyżowana z
					innymi rasami. Dziś powszechnie uznaje się, iż ragdolle są rasą wywodzącą się z skrzyżowań kotów
					birmańskich, perskich oraz burmańskich.
				</div>

				<div>
					Pierwszą kotką, której właścicielką została Ann Baker była podobna do rasy burmańskiej kotka o imieniu
					Buckwheat, natomiast następną - dwukolorowa kotka Raggedy Ann Fugianna (obydwie pochodziły z miotu od
					Josephine i różnych, przypadkowych kocurów). Ojcem tej drugiej był kocur i syn Josephine - Daddy
					Warbucks, który uważany jest do dzisiaj za ojca ragdolli.
				</div>

				<div>
					Ann Baker rozpoczęła na szeroką skalę akcję promującą nową rasę. Wkrótce stało się o rasie tej głośno w
					amerykańskich mediach. Baker zastrzegła nazwę rasy oraz założyła organizację International Ragdoll Cat
					Association (IRCA). W Stanach Zjednoczonych ragdolle zostały zarejestrowane jako rasa w 1965 roku, w
					Wielkiej Brytanii w 1983.
				</div>

				<h2>Wygląd ragdolla</h2>

				<div>
					Wzorzec dopuszcza cztery ubarwienia sierści: seal, niebieski, czekoladowy, lila w trzech odmianach:
					kolorpoint, mitted oraz dwukolorowy. Ragdolla można zaliczyć do dużych kotów o grubych kościach. Jego
					głowa jest średniej wielkości, w kształcie trójkąta. Oczy przeważnie niebieskie, owalne i szerokie,
					natomiast uszy skierowane ku przodowi. Ogon jest gęsty i puszysty.
				</div>

				<h2>Charakter</h2>

				<div>
					Ragdoll jest mało ruchliwy, wyjątkowo ufny i spokojny. Lubi obecność dzieci oraz jest bardzo cichy i
					łagodny. Akceptuje towarzystwo innych kotów. Powstało wiele mitów na ich temat. Wbrew panującemu
					stereotypowi ragdolle nie są wytrzymałe na ból i odczuwają go tak jak inne koty.
				</div>
			</div>
		),
		en: (
			<div>
				<h2>The name "Ragdoll"</h2>
				<div>
					Ragdolls were first created in the 1960s by Ann Baker, a Persian cat breeder in California. The name
					"Ragdoll" is derived from Baker's belief that her cat Josephine was genetically manipulated during
					treatment at the local university, after she was run over by a car, resulting in future Ragdolls being
					relaxed when picked up, and unable to feel pain. Today, scientists reject this theory as Ragdoll
					experiences pain in the same way as any other breed of cat. As far as the "floppiness" is concerned, cats
					from any breed could present this trait—it is not caused by any genetic characteristic, but rather by the
					way in which the kitten is raised and socialised.
				</div>
				<h2>More about History</h2>
				<div>
					Some of the original stock consisted of hardy, free-roaming street cats. Ms. Baker created the
					foundations of the Ragdoll breed by selecting kittens out of Josephine, a semi-feral longhaired white
					female Persian/Angora type, sired by several unknown male Birman-like or Burmese-like cats, one with
					Siamese type markings. Out of those early litters came Blackie, an all black Burmese-like male and Daddy
					Warbucks, a seal point with white feet. Daddy Warbucks sired the founding bi-color female Fugianna, and
					Blackie sired Buckwheat, a dark brown/black Burmese-like female. Both Fugianna and Buckwheat were
					daughters of Josephine. All Ragdoll and RagaMuffin cats are descended from Ann Baker's cats through
					matings of Daddy Warbucks to Fugianna and Buckwheat. By selecting individuals with the look and
					temperament she wanted for her breeding program, Ann Baker created the standard Ragdoll type.
				</div>
				<div>
					Baker, in an unusual move, spurned traditional cat breeding associations. She trademarked the name
					"Ragdoll," set up her own registry—International Ragdoll Cat Association (IRCA)—and enforced stringent
					standards on anyone who wanted to breed or sell cats under that name. The Ragdolls were also not allowed
					to be registered in other breed associations. In 1975, a group broke rank with IRCA with the aim of
					gaining mainstream recognition for the Ragdoll. This group, which included Denny and Laura Dayton
					eventually developed the Ragdoll standard currently accepted by major cat registries. The breed was
					selectively bred over many years for desirable traits, such as large size, gentle demeanor, and a
					tendency to go limp when picked up, as well as the striking pointed coloration. In 1994, a second group
					decided to leave the IRCA and form their own group due to increasingly strict breeding restrictions. This
					group later established the Ragamuffin breed. Keep in mind at this point that the ragdoll has a name that
					no one could use until Ann Baker's trademark on "Ragdoll" was not renewed by her in 2005. Due to how the
					ragamuffins are bred (every 5th generation with certain other cats including the ragdoll), they are two
					distinct breeds of cats. Even though ragamuffins are in-bred with ragdolls to keep a similar ragdoll
					temperament they may show behavior and physical differences.
				</div>
				<h2>Weight Range</h2>
				<div>
					10-20 lb (4.5-9 kg). Some have been reported as big as 35 pounds. Males have large manes. Large size is
					one goal of Ragdoll cat breeders. Ragdolls are the largest domesticated cat breed[dubious – discuss].
				</div>
				<h2>Temperament</h2>
				<div>
					The Ragdoll cat typically has a very gentle and relaxed temperament. When socialized from birth they are
					attentive and affectionate members of the family that enjoy and seek out human companionship. Ragdoll
					cats remain playful throughout their lives, adjust well to children and pets and are sometimes called
					"puppy-cats" because of their propensity to follow their owners from room to room and meet them at the
					door. Ragdoll cats are demurely vocal, careful with their claws and teeth when in play, plus forgiving of
					accidental mistreatment. Because of their non-defensive nature, a Ragdoll should never be allowed
					outdoors unattended.
				</div>
				<h2>Grooming</h2>
				<div>
					Ragdolls require light grooming and great nutrition to give them an overall fluffy and healthy
					appearance. Ragdolls shed minimally. Their coats are easier to manage than many other long-haired breeds
					with just weekly combing to remove loose hairs and prevent mats. Bathing is rarely needed but
					well-tolerated, as are nail-clippings and vet visits. Although this is a common stance regarding Ragdoll
					grooming, Ragdoll cats can and will develop significant matting especially when subjected to dry air and
					static conditions typical during winter heating seasons.
				</div>
				<h2>Varieties</h2>
				Ragdolls come in 6 different colors - seal, chocolate, flame, and "dilutes" blue, lilac and cream. There are
				three different patterns: Pointed (nose, ears, tail and paws in the specific colors & no white), Mitted -
				white paws, chin and tummy, with or without a blaze (a white line on the face), and Bicolor—white tall
				socks, white inverted 'V' on the face, white tummy and often white patches on the back. Variations include a
				three-color "tortie-point" (mottled seal or blue with red present) and lynx (striped pattern on tail, face
				and points) which can be found in all 3 patterns.
			</div>
		)
	},
	rtoy: {
		pl: (
			<div>
				<h2>Rosyjski Toy Terrier</h2>
				<div>
					Russian Toy Terrier to jedna z najmniejszych ras psów, została założona w Rosji w XX wieku. Przodkowie
					tej rasy byli hodowani jako rasa towarzysząca rosyjskiej szlachcie, łapacza szczurów i stróża. Rasa może
					występować w odmianach gładkich i długowłosych, ale żadna z nich nie wymaga dużo pielęgnacji. Russian Toy
					Terrier jest wyjątkowo drobny i powinien mieć ogólnie kwadratowy, elegancki wygląd. Ze względu na swój
					niewielki rozmiar i małą strukturę kości rosyjski toy terrier może być delikatny.
				</div>
				<div>
					Chociaż rosyjski toy terrier jest mały, lubi ruch bardziej niż inne rasy o podobnych rozmiarach. Jego
					rozmiar oznacza, że ​​jest idealny do środowisk miejskich, jest niezwykle inteligentny i bardzo szybko
					się uczy. Rasa ma czuły, odważny i wesoły charakter i jest niezwykle lojalna. Może stać się terytorialny
					wokół swojej rodziny i być bardzo głośny, ale nie powinien być agresywny - to jednak oznacza, że ​​może
					być podatny na lęk separacyjny.
				</div>
			</div>
		),
		en: (
			<div>
				<h2>Russian Toy Terrier</h2>
				<div>
					The Russian Toy Terrier is one of the smallest breeds of dog and, the breed as we know it today, was
					formed in Russia in the 20th century. Its ancestors were bred as a companion breed for the Russian
					nobility, a rat catcher and a watchdog. The breed can come in smooth and longhaired varieties, but
					neither requires much grooming. The Russian Toy Terrier is extremely petite and should have an overall
					square, elegant appearance. Due to its tiny size and small bone structure, the Russian Toy Terrier can be
					fragile.
				</div>

				<div>
					Whilst the Russian Toy Terrier is small, it does enjoy exercise more than some other similarly small
					sized breeds. Its size means it is ideal for urban environments and it is extremely intelligent and very
					quick to learn. The breed has an affectionate, bold and cheerful character and is extremely loyal. It can
					become territorial around its family and be very vocal but should not be aggressive – this does, however,
					mean it can be prone to suffering from separation anxiety.
				</div>
			</div>
		)
	},
	mco: {
		pl: (
			<div>
				<h2>Maine Coon</h2>

				<h2>Głowa:</h2>
				<div>Trójkątna z dobrze wykształconym, wyraźnie szerokim i kanciastym pyszczkiem.</div>
				<h2>Szata:</h2>
				<div>
					Półdługie, dobrze chroniące przed wodą i zimnem, co jest bardzo ważne dla życia na swobodzie. Ciało jest
					chronione przed przechłodzeniem grubą warstwą miękkiego i delikatnego podszycia, nad które wystają mocne
					i długie włosy okrywowe. Długość futra jest zróżnicowana: na brzuchu i piersi oraz w okolicy genitaliów
					jest ono dłuższe, natomiast na grzbiecie, barkach i kończynach krótsze. W zimie dochodzi do tego jeszcze
					okazały i gęsty kołnierz na głowie i piersi, a także dłuższe partie futra na tylnych kończynach
					przypominające spodnie typu pumpy.
				</div>
				<h2>Uszy:</h2>
				<div>
					Wysoko stojące, o szerokiej nasadzie powinny być ostro zakończone i mieć typowy dla rasy pędzelek włosów.
				</div>
				<h2>Oczy:</h2>
				<div>
					Duże powinny być szeroko rozstawione i trochę ukośne, jednak nie do tegostopnia, aby wyglądały jak
					orientalne. Koty maine coon mają charakterystyczne"sowie" spojrzenie, które sprawia, że nawet dorosłe,
					okazałe osobnikiwyglądają "dziecinnie".
				</div>
				<h2>Nos:</h2>
				<div>Długi i prosty oraz wydatny podbródek, w profilu tworzą jedną linię.</div>
				<h2>Ogon:</h2>
				<div>
					Długi i puszysty, który zimą jest szczególnie gęsty, zapewnia żyjącym na otwartej przestrzeni kotom tej
					rasy dodatkową ochronę przed utratą ciepła, gdy go sobie ułożą wokół głowy. Ogon jest "reprezentacyjną"
					częścią ciała kotów maine coon i u wzorcowych osobników osiąga długość równą długości reszty ciała, co
					sprawia, że koty te wydają się jeszcze większe i potężniejsze.
				</div>
				<h2>Kończyny:</h2>
				<div>Silnie zbudowane i długie z charakterystycznymi dużymi i gęsto owłosionymi ŁAPAMI.</div>
				<h2>CO TO MAINE WIADOMO, A CO ZNACZY COON?</h2>
				<div>
					Podczas ruchu zwierzęcia włosy ogona "latają", co wywołuje skojarzenia z szopami praczami. Stąd drugi
					człon nazwy rasy coon, co znaczy poprostu szop.
				</div>
				<h2>Historia:</h2>
				<div>
					Protoplastami tej rasy są ciężkie koty wiejskie i koty długowłose przywiezione do stanu Maine przez
					kupców i marynarzy z Europy. Koty te pokazano po raz pierwszy w Nowym Jorku w 1860r. a zarejestrowano w
					1861r. Zwyciężyły na wystawie w Madison Square Garden 1 1895r. Jednak ich popularność zmalała po
					sprowadzeniu do USA kotów perskich i zaczęła rosnąć dopiero w latach 50. naszego stulecia. W 1976r. koty
					te zostały oficjalnie uznane w USA.
				</div>
				<h2>Temperament:</h2>
				<div>
					Maine coon są atrakcyjne nie tylko z powodu swego wyglądu. W wyniku starannej selekcji stały się rasą nie
					tylko piękną, lecz przede wszystkim pożądaną z tego względu, że jej przedstawiciele przywiązują się do
					człowieka i - w porównaniu zimnymi rasami kotów i innymi gatunkami zwierząt domowych - są bardziej
					towarzyskie i łatwiejsze we współżyciu. Szybko rosną oraz są odporne. Mimo swego okazałego ciała
					wyglądają niezwykle wytwornie. Kotki maine coon są nadzwyczaj troskliwymi matkami i odznaczają się
					niezawodnym instynktem macierzyńskim. Wychowują swoje kocięta, których często jest 6 lub 7, bez żadnych
					kłopotów, przy czym naturalne porody są u tej rasy normą. Nawet kocury niekiedy biorą udział w
					wychowywaniu młodych, które szybko rosną i w wieku 9lub 10 miesięcy osiągają imponującą masę ciała
					dochodzącą do 5 kg
				</div>
				<h2>Hodowla:</h2>
				<div>
					Koty tej rasy hoduje się dość intensywnie już od początku lat 70. Jednak ich fenotyp do dzisiaj
					przypomina dzikich i półdzikich przodków ze stanu Maine w Stanach Zjednoczonych, od którego pochodzi
					nazwa rasy. Na farmach nadal spotyka się rosłe, pozostawione naturze "coony", jak je się czule nazywa,
					odpowiadające fenotypowi kotów, jakie pierwsi osadnicy prawdopodobnie trzymali w celu tępienia szkodników
					żywności. Mimo zaciętego sporu w ostatnich latach na temat fenotypu dzisiejszych kotów rasy maine coon są
					one nadal duże, muskularne i nie należy do rzadkości spotkanie kocura, którego całkowita długość ciała
					przekracza metr. Również spór między zwolennikami typu amerykańskiego a zwolennikami typu europejskiego,
					dotyczący kierunku hodowli, nie wpłynął na zmianę dużego i długiego ciała. Można więc mieć nadzieję, że
					także w przyszłości będzie się hodowało koty maine coon z nastawieniem na zachowanie cech wzorca, jak
					budowa ciała i jakość futra.
				</div>
				<h2>Pielęgnacja:</h2>
				<div>
					Main Coon'y potrzebują porządnego wyczesania (grzebieniem i szczotką) kilka razy w tygodniu ze
					szczególnym zwróceniem uwagi na "portki", pachwiny i kryzę.
				</div>
				<h2>Odmiany:</h2>
				<div>
					Poza umaszczeniem czekoladowym, lila i takim jak u syjamów, koty main coon wyhodowano w dwóch grupach
					kolorów, mianowicie z dzikim umaszczeniem agouti i jednobarwne. Ostatnio większego znaczenia nabierają
					jednobarwne non-agouti main coon.
				</div>
			</div>
		),
		en: (
			<div>
				<h2>Maine Coon</h2>

				<div>
					The Maine Coon is one of the largest breeds of domestic cat, known for its high intelligence and
					playfulness as well as its distinctive physical appearance. The breed is one of the oldest natural breeds
					in North America specifically native to the state of Maine (where it is the official State Cat). This cat
					is known as "Maine Coon", "coon-cat", "Maine Cat" or (colloquially) "the gentle giant".
				</div>

				<h2>Origin</h2>

				<div>
					The Maine Coon is a natural cat breed that originated in Maine. A journal article was published about the
					coon-cat of the late 1800s stating: "... all of them come from Maine, simply for the reason that the
					breed is peculiar as yet to that State." "Coon-cats have been recognized as a distinct breed in Maine for
					so long that the memory of the oldest inhabitant runs back to their beginning." "You will find them in
					almost any village in that part of the world."
				</div>

				<div>
					The Maine cat was recognized as a distinct breed of cat long ago and known as the "coon-cat" in the mid
					1800s prior to the Civil War in recorded history and documented early descriptions of the Maine cat by a
					well known and celebrated Maine author who lived in that era prior to 1850.
				</div>

				<div>
					In the 17th and 18th centuries, domestic cats brought over on ships faced very severe winters in Maine,
					where only the strongest and most adaptable cats survived. "Natural selection (and climate) has had a
					significant effect on (longhair/Maine Coon) gene frequency in the 200-300 generations since domestic cats
					were introduced to America." The Maine Coon developed outdoors into a large, rugged cat with a
					water-resistant, thick, longhair coat and a hardy constitution. The fur coat developed outdoors into a
					coat that is particularly unique and distinct from other long-hair breeds.
				</div>

				<h2>Folklore</h2>

				<div>
					The origin of the breed (and its name) has several, often fantastic, folklore surrounding it - all coming
					from Mainers' story-telling and dry sense of humor. One tale comes from this journal account of actual
					story-telling in 1901 by the down east locals.
				</div>

				<div>
					"Strange to say, there are comparatively few people south or west of New England who know what a coon-cat
					is. If you ask that question 'down in Maine,' however, the citizens will seem surprised at your
					ignorance, and will explain to you, in a condescending way, that the creature in question is half raccoon
					-- the descendant of 'a cross between a 'coon and a common cat.'" Though biologically impossible, this
					false story was the result of Mainers' good old leg-pulling and the gullibility of tourists. According to
					that 1901 account (as you can see), these cats were still referred to as "coon-cats".
				</div>

				<div>
					A related story is that the cat was named after a ship's captain named Coon who was responsible for the
					cat reaching Maine shores. This story comes from a Mainer named Molly Haley (prior to 1820) as her oral
					history of the cat’s name that was published in this 1986 Maine newspaper article.
				</div>

				<div>
					(Born 1911 Lida Tarbox) "Her father's account of the Maine Coon goes back to his great-grandmother, Molly
					Haley, who lived on the Haley farm next to the Tarboxes', just up from the 'pool,' or gut where the Saco
					River and the Atlantic Ocean meet. This was before Maine became a state (1820) and when the four-masted
					schooners hauled cargo to Maine from around the world.
				</div>

				<div>
					A cabin boy named Tom Coon, from which the 'coon' cat purportedly gets its name, worked aboard the
					sailing vessel Glen Laurie. One of his jobs when ashore was to collect cats, which were then used to rid
					the sailing vessel of wharf rats. On one of these rat-catcher expeditions, Tom smuggled in a beautiful
					longhair. The safe harbor for both the first coon and her subsequent litter was the Tarbox farm at
					Biddeford Pool, where the Glen Laurie anchored to take on supplies at the Cutts store at the Pool. When
					the cabin boy became a captain, he continued to bring the exotic long-hairs to the farm during his ocean
					voyages." (Documentation of a whaling Captain Coon and his ocean-going family exists in the Maine State
					Library.)
				</div>

				<div>
					Another story is a legend from an island dwelling mainer that the breed sprang from pet cats that Marie
					Antoinette sent to Wiscasset, Maine when she was planning to escape from France during the French
					Revolution. This story is told in "The Legend of Rosalind of Squam Island".
				</div>

				<div>
					Nevertheless, most breeders today believe that the breed originated in matings between perhaps
					pre-existing shorthaired domestic cats and overseas longhairs. Maine Coons are similar in appearance to
					both the Norwegian Forest Cat and to the Siberian.
				</div>

				<h2>Physical characteristics</h2>

				<div>
					Maine Coons are very large and energetic cats, sometimes weighing up to around 11-12 kilograms (25
					pounds); the average weight is 6 to 9 kilograms (13-20 pounds) for adult males and less (7-11 pounds) for
					females. Males may grow to a length in excess of 1 meter (40 inches, measured from the tip of the nose to
					the end of the tail); as of 2006, the longest cat on record is a male Maine Coon measuring 122cm (48
					inches) in length. This cat weighed in at 35 pounds. Growth to full size often takes longer than for most
					cats, with Maine Coons usually reaching full size at age four or five.
				</div>

				<div>
					The most common color/pattern in the breed is brown tabby. Maine Coons are recognized in all colors,
					including tortoiseshell, except for chocolate, lavender, ticked tabby, and the point-restricted
					("Siamese") pattern. Eye colors include green, green-gold, or gold eyes. Blue eyes, or one blue eye with
					one gold eye, are possible in white coat cats. Some share similar facial markings, for example, a
					distinct "M" shape on the forehead.
				</div>

				<div>
					Maine Coons have medium-long, dense fur, with longer hair, or a ruff, on their chests similar to the mane
					of a lion (which is why the breed is sometimes humorously called the "Mane Coon"). Their fur consists of
					two layers—an undercoat and an additional layer of longer guard hairs—which gives the breed their key
					physical feature. The fur is generally very soft. Maine Coons have long hair on the backs of their legs
					(called pantaloons or britches) and between their toes which helps to keep them warm in the cold. They
					also have bushy plumed tails and broad, angular heads, squared-off muzzles and large, wide-set ears
					topped with tufts of fur (known as 'Lynx-tips'). Their tails can be so bushy that the Maine Coon has
					earned the nickname the 'tail with a cat attached to it'.
				</div>

				<div>
					Most Maine Coons keep their fur in good order without the need for additional human grooming. Some Maine
					Coons may have tufts of fur growing from behind their ears on the sides of their heads. The appearance of
					these tufts can change over time, sometimes appearing quite large and sometimes being not all that
					noticeable.
				</div>

				<div>
					While the Maine Coon may be polydactyl (having one or more extra toes on their paws), this trait is not
					yet available in show cats—only in pet cats. This trait is finding a world-wide resurgence and is
					increasingly popular, as it seems to some, that the polydactyl Maine Coon exhibits even more dexterity
					and intelligence than the normal-footed. They are nick-named "snowshoe cat" because they can walk through
					snow more easily, but most often though, they are simply called polys. Polydactyl bloodlines have long
					been, and continue to be, registered to preserve this trait of the breed. Modern show types are rarely
					polys. Polydactyls are indeed behind many show champions around the world. The show type "breeds" still
					ignore the Maine Coon Polydactyl Standard and has culled this vast poly portion of the original gene pool
					(characteristics and features) from the show types. The original polydactyl incident was estimated to be
					around 40%. Centuries ago it was highly likely that a polydactyl cat from Maine would be a Maine Coon.
					Polydactylism was (and still is) a distinct visible indicator of a coon-cat's original, natural Maine
					heritage. Native Maine origin coon-cats still exist and are essential to the health and survival of the
					breed.
				</div>

				<div>
					In a mating of heterozygous parents, the kittens are 25% normal-footed, 50% heterozygous for polydactyly,
					25% homozygous for polydactyly on average.
				</div>

				<h2>Behavioral characteristics</h2>

				<div>
					Maine Coons are a breed distinguished by high intelligence, dexterity, and playfulness. They have a
					tendency to use their front paws extensively (often curling the paw round to pick objects up) and as a
					consequence will easily learn to open cabinet doors, turn on water faucets, flush toilets, or pick up
					small objects. Some Maine Coons will eat, or even drink, from their paws, rather than from the bowl
					itself.
				</div>

				<div>
					Due to their above-average intelligence, Maine Coons are known to be one of the easiest cat breeds to
					train. Maine Coons are noted for their ability to trill their meows, which sounds like a combination of a
					purr and a meow, and they tend to make this sound when happy or startled. When they do meow, it tends to
					be very high in pitch, in comparison to other breeds. They are noted for rarely eating alone, preferring
					to eat in the company of other cats or humans. Maine Coons are not known to be "lap cats" but of course,
					this may depend on the individual cat and some may prefer laps.
				</div>

				<div>
					Some Maine Coons enjoy playing with, but not usually in, water. They may dip toys in their water bowls
					before playing with them, or just tip the water bowl over. They may also skim their paws across the
					surface of their water bowl or dunk their paw in and drink water from their paws.
				</div>

				<div>
					Maine Coons can be very dog-like in their behavior. Playing fetch is a favorite game. As with dogs, they
					will bring their ball, drop it at the feet of their intended playmate and wait for the ball to be thrown.
					They will often accompany their owner on chores like getting the mail, walking the dog, and may also come
					when beckoned, even if outside. Maine Coons are typically very calm and listen to their owners.
				</div>

				<h2>Health topics</h2>

				<div>
					Maine Coons are generally healthy and hardy. They thrive on better brands of cat foods and sometimes
					adding fish oils to the diet helps keep their coat and skin in top health. However, they are often quite
					picky. Maine Coon breeders have worked hard over many years to produce hardy, healthy and beautiful
					kittens. Almost all knowledgeable Maine Coon breeders are able to avoid health problems because of
					significant new advances in veterinary medical testing in recent years. Past problems did include
					hypertrophic cardiomyopathy, hip dysplasia, for a brief time: polycystic kidney disease (continues to be
					rare), and typical feline conditions such as gum inflammation or luxating patellas (are non-breed
					specific, and may occur in any feline.)
				</div>

				<div>
					Mutation in the gene that codes for cardiac myosin binding protein C has been shown to cause Hypertrophic
					cardiomyopathy in one particular genetic line of the Maine Coon cat population. Because this line is very
					popular with show-hobby breeders, approximately one third of Maine Coon cats tested for the mutation have
					tested positive, and have been removed from the breeding population, although this population sampling is
					most likely biased, because the high percentage of cats tested were related to that particular line.
					Breeders now use the latest DNA sampling methods to improve the breed and ensure its excellent future.
					Many healthy and hardy Maine Coon lines now exist and the future of the breed is extremely bright.
				</div>

				<div>
					Until 1988, taurine deficiency was a common cause of dilated cardiomyopathy in all cats, including Maine
					Coons. Since the pet food industry started adding more taurine to cat food in the late 1980s, this kind
					of cardiomyopathy is rare. Taurine-related cardiomyopathy can be cured with the addition of the nutrient
					to the diet, but genetic hypertrophic cardiomyopathy causes a permanent thickening of the left ventricle
					and is not curable.
				</div>

				<div>
					As with all breeds, well outcrossed pedigrees that are outcrossed in the early generations and outcrossed
					further in later generations are important to vitality, disposition, and longevity.
				</div>
			</div>
		)
	}
};
