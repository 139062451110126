import { BasicComponent } from "haxt";
import "./MainNav.scss";
import { RouterOutlet } from "./Router";

export class MainNav extends BasicComponent {

	public init() {
		RouterOutlet.onUpdate.addCallback(() => {
			this.update();
		});
	}

	private navMenuOpened = false;

	@BasicComponent.bind()
	private toggleNavMenu() {
		this.navMenuOpened = !this.navMenuOpened;
		this.update();
	}

	@BasicComponent.bind()
	private navigate(event: MouseEvent, url: string) {
		RouterOutlet.setPath(url);
		this.navMenuOpened = false;
		this.update();
		event.preventDefault();
	}

	@BasicComponent.bind()
	private changeLang(event: MouseEvent, lang: string) {
		RouterOutlet.changeLang(lang);
		this.update();
		event.preventDefault();
	}

	public render() {
		let lang = RouterOutlet.lang;
		if (lang === "en") {
			return (
				<div class="MainNav">
					<a class="logo-top" onClick={(event) => this.navigate(event, "")} href={RouterOutlet.get("")} />
					<a class="logo-name" onClick={(event) => this.navigate(event, "")} href={RouterOutlet.get("")} />
					<div class="nav">
						<div class={{ menu: true, [this.navMenuOpened ? "opened" : "closed"]: true }} onClick={this.toggleNavMenu} />
						<a class={{ "lang-pl": true, [RouterOutlet.lang === "pl" ? "active" : "inactive"]: true }}
							onClick={(event) => this.changeLang(event, "pl")} href={RouterOutlet.getLang("pl")} />
						<a class={{ "lang-en": true, [RouterOutlet.lang === "en" ? "active" : "inactive"]: true }}
							onClick={(event) => this.changeLang(event, "en")} href={RouterOutlet.getLang("en")} />
						<a class="facebook" href="https://www.facebook.com/profile.php?id=100008815092651" />
					</div>
					<div class={{ "nav-menu": true, [this.navMenuOpened ? "opened" : "closed"]: true }}>
						<div class="items">
							<a onClick={(event) => this.navigate(event, "")} href={RouterOutlet.get("")}>Home</a>
							{/*<a onClick={(event) => this.navigate(event, "owned/rag")} href={RouterOutlet.get("owned/rag")}>Ragdoll</a>*/}
							<a onClick={(event) => this.navigate(event, "owned/rus")} href={RouterOutlet.get("owned/rus")}>Russian Blue</a>
							<a onClick={(event) => this.navigate(event, "owned/mco")} href={RouterOutlet.get("owned/mco")}>Maine Coon</a>
							<a onClick={(event) => this.navigate(event, "owned/rtoy")} href={RouterOutlet.get("owned/rtoy")}>Russian Toy Terrier</a>
							<a onClick={(event) => this.navigate(event, "contact")} href={RouterOutlet.get("contact")}>Contact</a>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div class="MainNav">
					<a class="logo-top" onClick={(event) => this.navigate(event, "")} href={RouterOutlet.get("")} />
					<a class="logo-name" onClick={(event) => this.navigate(event, "")} href={RouterOutlet.get("")} />
					<div class="nav">
						<div class={{ menu: true, [this.navMenuOpened ? "opened" : "closed"]: true }} onClick={this.toggleNavMenu} />
						<a class={{ "lang-pl": true, [RouterOutlet.lang === "pl" ? "active" : "inactive"]: true }}
							onClick={(event) => this.changeLang(event, "pl")} href={RouterOutlet.getLang("pl")} />
						<a class={{ "lang-en": true, [RouterOutlet.lang === "en" ? "active" : "inactive"]: true }}
							onClick={(event) => this.changeLang(event, "en")} href={RouterOutlet.getLang("en")} />
						<a class="facebook" href="https://www.facebook.com/profile.php?id=100008815092651" />
					</div>
					<div class={{ "nav-menu": true, [this.navMenuOpened ? "opened" : "closed"]: true }}>
						<div class="items">
							<a onClick={(event) => this.navigate(event, "")} href={RouterOutlet.get("")}>Home</a>
							{/*<a onClick={(event) => this.navigate(event, "owned/rag")} href={RouterOutlet.get("owned/rag")}>Ragdolle</a>*/}
							<a onClick={(event) => this.navigate(event, "owned/rus")} href={RouterOutlet.get("owned/rus")}>Rosyjskie Niebieskie</a>
							<a onClick={(event) => this.navigate(event, "owned/mco")} href={RouterOutlet.get("owned/mco")}>Maine Coony</a>
							<a onClick={(event) => this.navigate(event, "owned/rtoy")} href={RouterOutlet.get("owned/rtoy")}>Rosyjskie Toy Teriery</a>
							<a onClick={(event) => this.navigate(event, "contact")} href={RouterOutlet.get("contact")}>Kontakt</a>
						</div>
					</div>
				</div>
			);
		}

	}
}
